<template>
    <svg :id="chartname" class="multi-line-chart"></svg>
</template>

<style lang="scss" scoped>
</style>

<style lang="scss">
.multi-line-chart text {
  fill: #000;
  font: 14px "futura-pt-condensed";
  font-weight: 500;
  text-anchor: end;
}
.multi-line-chart .chart-line {
  fill: none;
  stroke: #f6cd5f;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-width: 2;
}
.multi-line-chart .line {
  fill: none;
}
.multi-line-chart .chart-circle {
  fill: #f6cd5f;
}
.multi-line-chart .domain,
.multi-line-chart .tick line {
  stroke: none;
}
.multi-line-chart path.line {
  stroke-width: 5px;
}
.multi-line-chart path.line:hover {
  stroke-width: 5.5px;
}

.multi-line-chart text.title-text {
  font: 25px "futura-pt-condensed";
  font-weight: 800;
}

.multi-line-chart text.party-yaxis-info {
  font: 15px "futura-pt-condensed";
  font-weight: 500;
  text-anchor: start;
  cursor: pointer;
}

.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}

.grid path {
  stroke-width: 0;
}
</style>

<script>
import * as d3 from "d3";
import parties from "@/assets/parties/parties.json";

export default {
  props: ["data", "xaxis_image", "chartname"],
  data() {
    return {
      parties,
      rendered: false,
      width: this.getWidth(),
      height: this.getHeight(),
      margin: {
        top: 20,
        right: 20,
        bottom: 40,
        left: 50
      }
    };
  },
  methods: {
    getWidth() {
      if (window.innerWidth > 1100) {
        return (window.innerWidth / 2) * 0.85 - 70;
      } else {
        return window.innerWidth * 0.85 - 70;
      }
    },
    getHeight() {
      if (window.innerWidth > 1100) {
        return (window.innerWidth / 2) * 0.7 - 60;
      } else {
        return window.innerWidth * 0.7 - 60;
      }
    },
    onResize() {
      // TODO: On resize, the height becomes bigger and bigger. Rescale to some sort of aspect ratio?
      // https://bl.ocks.org/curran/3a68b0c81991e2e94b19

      this.width = this.getWidth();
      this.height = this.getHeight();

      //   this.width = this.$parent.$el.offsetWidth - (this.$parent.$el.offsetWidth / 3);
      //   this.height = this.$parent.$el.offsetHeight - (this.$parent.$el.offsetHeight / 2);
      document.querySelector("#" + this.chartname + " g").innerHTML = "";
      this.renderChart();
    },
    initialize: function() {
      this.svg = d3.select("svg#" + this.chartname);
      this.g = this.svg.append("g");

      // this.axis_x = this.g.append("g")
      //     .attr("class", "axis axis--x")

      // this.axis_y = this.g.append("g")
      //     .attr("class", "axis axis--y")
    },
    renderChart: function() {
      let self = this;
      this.rendered = true;

      // let parties_extended = this.parties;
      // parties_extended["KD"] = {"name" : "Kristendemokraterne"};

      var parseTime = d3.timeParse("%Y%m%d");

      var x = d3.scalePoint().range([10, this.width - this.margin.right]),
        y = d3.scaleLinear().range([this.height, 0]),
        z = d3.scaleOrdinal(d3.schemeCategory10);

      this.svg
        .attr("width", this.width + this.margin.left + this.margin.right)
        .attr("height", this.height + this.margin.top + this.margin.bottom);

      // this.x = d3.scalePoint().range([20, this.width - this.margin.right])
      // this.y = d3.scaleLinear().rangeRound([this.height, 0]);

      this.g.attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );

      // this.x.domain(renderData.map(function(d) { return d.axis; }))
      // this.y.domain([0, d3.max(renderData, function(d) { return d.count; })]);

      var line = d3
        .line()
        .curve(d3.curveCardinal)
        .x(function(d) {
          return x(d.date);
        })
        .y(function(d) {
          return y(d.count);
        });

      // this.axis_x
      //     .attr("transform", "translate(0," + (this.height + 20) + ")")
      //     .call(d3.axisBottom(this.x))
      //     .selectAll("text")
      //     .style("text-anchor", "middle");

      d3.csv("/static/parties_membership_final.csv").then(data => {
        // d3.json("https://stat.ogtal.dk/folketinget_api/v1/party/match/stats/klima").then((data) => {
        // console.log(data);

        var lineOpacity = "0.25";
        var lineOpacityHover = "0.85";
        var otherLinesOpacityHover = "0.1";
        // var lineStroke = "3px";
        var lineStrokeHover = "6px";

        var circleOpacity = "0.85";
        var circleOpacityOnLineHover = "0.25";
        var circleRadius = 3;
        var circleRadiusHover = 6;

        var duration = 250;
        var parties = data.columns.slice(1).map(function(id) {
          return {
            id: id,
            values: data.map(function(d) {
              return { date: parseInt(d.year), count: parseInt(d[id]) };
            })
          };
        });

        //x.domain(d3.extent(data, function(d) { return d.date; }));
        x.domain(
          data.map(function(d) {
            return d.year;
          })
        );

        // y.domain([0, 80000]);
        y.domain([
          d3.min(parties, function(c) {
            return d3.min(c.values, function(d) {
              return d.count;
            });
          }),
          d3.max(parties, function(c) {
            return d3.max(c.values, function(d) {
              return d.count;
            });
          }) + 10000
        ]);

        z.domain(
          parties.map(function(c) {
            return c.id;
          })
        );

        this.g
          .append("g")
          .attr("class", "axis axis--x")
          .attr("transform", "translate(0," + (this.height + 10) + ")")
          .call(d3.axisBottom(x))
          .selectAll("text")
          .attr("transform", function() {
            if (window.innerWidth < 1100) return "rotate(-65)";
            return "";
          })
          .style("text-anchor", "middle");

        let yAxis = d3.axisLeft(y).ticks(10); //.tickFormat(d3.format(","));
        var format = d3.format(",");

        this.g
          .append("g")
          .attr("class", "axis axis--y")
          .call(yAxis)
          .selectAll("text")
          .text(function(d) {
            return format(d).replace(/,/g, " ");
          });
        // .append("text")
        // .attr("transform", "rotate(-90)")
        // .attr("y", 6)
        // .attr("dy", "0.71em")
        // .attr("fill", "#000")
        // .text("Medlemmer");

        let lines = this.g.append("g").attr("class", "lines");

        let linegroup = lines
          .selectAll(".line-group")
          .data(parties)
          .enter()
          .append("g")
          .attr("class", "line-group")
          .on("mouseover", function(d, i) {
            self.svg
              .append("text")
              .attr("class", "title-text")
              .style("fill", z(d.id))
              .text(self.parties[d.id].name)
              .style("text-anchor", "middle")
              .attr(
                "x",
                (self.width + self.margin.left + self.margin.right) / 2
              )
              .attr("y", self.height / 2 + self.height / 5);

            self.svg
              .select(".line-party-" + d.id)
              .style("opacity", lineOpacityHover);
            self.svg
              .selectAll(".circle-group-party-" + d.id + " circle")
              .style("opacity", circleOpacityOnLineHover);
          })
          .on("mouseout", function(d) {
            self.svg.select(".title-text").remove();
            self.svg
              .select(".line-party-" + d.id)
              .style("opacity", lineOpacity);
            self.svg
              .selectAll(".circle-group-party-" + d.id + " circle")
              .style("opacity", circleOpacity);
          });

        let party = linegroup
          .append("path")
          .attr("class", function(d) {
            return "line line-party-" + d.id;
          })
          .attr("d", d => line(d.values))
          .style("stroke", (d, i) => z(d.id))
          .style("opacity", lineOpacity)
          .on("mouseover", function(d) {
            d3.selectAll(".line").style("opacity", otherLinesOpacityHover);
            d3.selectAll(".circle").style("opacity", circleOpacityOnLineHover);
            d3.select(this)
              .style("opacity", lineOpacityHover)
              // .style("stroke-width", lineStrokeHover)
              .style("cursor", "pointer");
          })
          .on("mouseout", function(d) {
            d3.selectAll(".line").style("opacity", lineOpacity);
            d3.selectAll(".circle").style("opacity", circleOpacity);
            d3.select(this)
              // .style("stroke-width", lineStroke)
              .style("cursor", "none");
          });

        /* Add circles in the line */
        lines
          .selectAll("circle-group")
          .data(parties)
          .enter()
          .append("g")
          //.attr("class", "circle-group")
          .attr("class", function(d) {
            return "circle-group circle-group-party-" + d.id;
          })
          .style("fill", (d, i) => z(d.id))
          .selectAll("circle")
          .data(d => d.values)
          .enter()
          .append("g")
          .attr("class", "circle")
          .on("mouseover", function(d) {
            d3.select(this)
              .style("cursor", "pointer")
              .append("text")
              .style("text-anchor", "middle")
              .attr("class", "text")
              .text(function(d) {
                return format(d.count).replace(/,/g, " ");
              })
              .attr("x", d => x(d.date))
              .attr("y", d => y(d.count) - 10);
          })
          .on("mouseout", function(d) {
            d3.select(this)
              .style("cursor", "none")
              .transition()
              .duration(duration)
              .selectAll(".text")
              .remove();
          })
          .append("circle")
          .attr("cx", d => x(d.date))
          .attr("cy", d => y(d.count))
          .attr("r", circleRadius)
          .style("opacity", circleOpacity)
          .on("mouseover", function(d) {
            d3.select(this)
              .transition()
              .duration(duration)
              .attr("r", circleRadiusHover);
          })
          .on("mouseout", function(d) {
            d3.select(this)
              .transition()
              .duration(duration)
              .attr("r", circleRadius);
          });

        linegroup
          // .append("text")
          // .datum(function(d) { return {id: d.id, value: d.values[d.values.length - 1]}; })
          // .attr("transform", function(d) { return "translate(" + x(d.value.date) + "," + y(d.value.count) + ")"; })
          // .attr("class", "party-yaxis-info")
          // .attr("x", 10)
          // .attr("dy", "0.35em")
          // .text(function(d) { return d.id; })
          .append("image")
          .datum(function(d) {
            return { id: d.id, value: d.values[d.values.length - 1] };
          })
          .attr("transform", function(d) {
            return (
              "translate(" + x(d.value.date) + "," + y(d.value.count) + ")"
            );
          })
          .style("cursor", "pointer")
          // .attr("class", "axis-img")
          .attr("x", 10)
          .attr("y", -10)
          // .attr("dy", ".35em")
          .attr("width", "20px")
          .attr("height", "20px")
          .attr("xlink:href", function(d) {
            if (d.id !== "KD") {
              return self.partyLogo(d.id);
            }
          });

        // var party = this.g.selectAll(".party")
        //     .data(parties)
        //     .enter().append("g")
        //     .attr("class", "party")

        // party.append("path")
        //     .attr("class", "line chart-line")
        //     .attr("d", function(d) { return line(d.values); })
        //     .style("stroke", function(d) { return z(d.id); })
        //     .on("mouseover", function(d) {
        //         self.svg.append("text")
        //             .attr("class", "title-text")
        //             .style("fill", z(d.id))
        //             .text(self.parties[d.id].name)
        //             .attr("text-anchor", "middle")
        //             .attr("x", (self.width + (self.margin.left + self.margin.right))/2)
        //             .attr("y", 50);
        //         })
        //     .on("mouseout", function() {
        //         self.svg.select(".title-text").remove();
        //     })

        // party.append("text")
        //     .datum(function(d) { return {id: d.id, value: d.values[d.values.length - 1]}; })
        //     .attr("transform", function(d) { return "translate(" + x(d.value.date) + "," + y(d.value.count) + ")"; })
        //     .attr("x", 10)
        //     .attr("dy", "0.35em")
        //     .style("font", "10px sans-serif")
        //     .style("text-anchor", "start")
        //     .text(function(d) { return d.id; });
      });
    },
    partyLogo: function(party) {
      return require(`@/assets/parties/logos/new/${party}_small.png`);
    }
  },
  mounted: function() {
    this.initialize();
    window.addEventListener("resize", this.onResize);
    // this.onResize();
    this.renderChart();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    // data: function() {
    //   this.change(this.data);
    // },
    // selectedPeriod: function() {
    //   this.change(this.data);
    // }
  }
};
</script>

<template>
  <div class="outer-container">
    <div class="content-container" v-if="partyData">
      <!-- intro -->
      <SectionTopicIntro id="section-topic-intro" :topicName="partyID" view="party" />
      <SectionPartyIntro id="section-party-intro" :partyID="partyID" />
      <SectionPartySpeakers id="section-party-speakers" :sectionData="partyData.PartySpeakers" :partyID="partyID" />

      <!-- voting -->
      <SectionIntroVoting id="section-intro-voting" view="party" />
      <SectionPartyFriends id="section-party-friends" :sectionData="partyData.PartyFriends" :partyID="partyID" />

      <!-- speech -->
      <SectionIntroSpeeches id="section-intro-speeches" view="party" />
      <SectionPartyIssues id="section-party-issues" :partyID="partyID" />
      <SectionPartyWords id="section-party-words" :sectionData="partyData.SectionPartyWords" :partySpeakers="partyData.PartySpeakers" :partyID="partyID" />
      <SectionWordBrowser id="section-word-browser" view="party" :partyID="partyID" :searchwords="partyData.SectionPartyWords.govPeriods" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
</style>

<script>
// @ is an alias to /src
import axios from "axios";
import settings from "@/assets/styles/settings.scss";

import SectionTopicIntro from "@/sections/SectionTopicIntro/SectionTopicIntro";
import SectionPartyIntro from "@/partySections/SectionPartyIntro/SectionPartyIntro";
import SectionPartySpeakers from "@/partySections/SectionPartySpeakers/SectionPartySpeakers";

import SectionIntroVoting from "@/sections/SectionIntroVoting/SectionIntroVoting.vue";
import SectionPartyFriends from "@/partySections/SectionPartyFriends/SectionPartyFriends";

import SectionIntroSpeeches from "@/sections/SectionIntroSpeeches/SectionIntroSpeeches.vue";
import SectionPartyIssues from "@/partySections/SectionPartyIssues/SectionPartyIssues";
import SectionPartyWords from "@/partySections/SectionPartyWords/SectionPartyWords";
import SectionWordBrowser from "@/sections/SectionWordBrowser/SectionWordBrowser";

export default {
  name: "party",
  components: {
    SectionTopicIntro,
    SectionPartyIntro,
    SectionPartySpeakers,
    SectionIntroVoting,
    SectionPartyFriends,
    SectionIntroSpeeches,
    SectionPartyIssues,
    SectionPartyWords,
    SectionWordBrowser
  },
  data() {
    return {
      partyData: null,
      settings
    };
  },
  methods: {
    setPartyData: function(data) {
      this.partyData = data;
    }
  },
  computed: {
    partyID: function() {
      return this.$route.params.partyID;
    }
  },
  beforeRouteEnter(to, from, next) {
    axios
      .get(`/static/parties/party_${to.params.partyID}.json`)
      .then(response => {
        next(vm => vm.setPartyData(response.data));
      });
  },
  beforeRouteUpdate(to, from, next) {
    axios
      .get(`/static/parties/party_${to.params.partyID}.json`)
      .then(response => {
        this.partyData = response.data;
        next();
      });
  }
};
</script>

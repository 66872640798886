<template>
  <div class="outer-container">
    <div class="dropdown-area">
      <TopicSelect :selectedTopicID="selectedTopicID" :topicsList="topicsList" />
    </div>
    <div class="chart-container">
      <HorizontalRange :base="partyID" :rangeData="sectionData.govPeriods[selectedPeriod].filter(x => x.topicID === selectedTopicID)[0].FriendsData.filter(x => x.partyID !== this.partyID)" :selectedPeriod="selectedPeriod" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.outer-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dropdown-area {
  position: absolute;
  top: 23.5%;
  left: 50%;
  transform: translateX(-50%);
  width: 48%;
  height: 8%;
  z-index: 99;
}

.chart-container {
  position: relative;
  width: 90%;
  height: 15%;
}
</style>

<script>
import axios from "axios";
import TopicSelect from "@/components/TopicSelect";
import HorizontalRange from "@/components/HorizontalRange";
export default {
  props: ["partyID", "sectionData"],
  components: {
    HorizontalRange,
    TopicSelect
  },
  data() {
    return {
      selectedPeriod: "allPeriods",
      showAverage: "topic",
      showTopicSelect: false,
      selectedTopicID: 99
    };
  },
  methods: {
    selectTopic: function(event) {
      this.selectedTopicID = event;
      // this.showTopicSelect = false;
    },
    partyLogo: function(party) {
      return require(`@/assets/parties/logos/new/${party}_med.png`);
    },
    setSelectedPeriod: function(event) {
      this.selectedPeriod = event;
    },
    setSelectedTopic: function(event) {
      this.selectedTopicID = event;
    }
  },
  computed: {
    topicsList: function() {
      return this.sectionData.govPeriods[this.selectedPeriod].map(x => {
        let response = {
          topicID: x.topicID,
          topicName: x.topicName
        };
        return response;
      });
    }
  },
  created() {
    this.$eventBus.$on("selectedPeriod", this.setSelectedPeriod);
    this.$eventBus.$on("selectedTopic", this.setSelectedTopic);
    axios
      .get("/static/periods.json")
      .then(response => (this.periods = response.data));
  },
  beforeDestroy() {
    this.$eventBus.$off("selectedPeriod");
    this.$eventBus.$off("selectedTopic");
  }
};
</script>

<template>
  <div class="horizontal-range-container">
    <div class="line"></div>
    <div class="images-container">
      <template v-if="dataType === 'parties'">
        <div class="range-image base-image">
          <div class="logo-container">
            <PartyLogo :party="base" :size="'small'" :circle="true" />
          </div>
        </div>
        <template v-for="(partyname, partyID, index) in parties">
          <div v-if="normalizedRangeData.some(x => x.partyID === partyID)" :key="index" class="range-image" :style="getImageStyle(normalizedRangeData.filter(x => x.partyID === partyID)[0])">
            <div class="logo-container">
              <PartyLogo :party="partyID" :size="'small'" :circle="true" />
            </div>
            <div v-if="type === 'vote'" class="info-card info-card-vote">
              <span><b>{{parties[base].name}}</b> stemte sammen med <b>{{partyname.name}}</b> i {{rangeData.filter(x => x.partyID === partyID)[0].percentage.toFixed(0)}} % af tilfældene.</span>
            </div>
            <div v-else class="info-card info-card-party">
              <span>{{partyname.name}}</span>
            </div>            
          </div>
        </template>
      </template>
      <template v-else>
        <div class="range-image base-image">
          <div class="logo-container">
            <img :src="getImage(base.politicianID)" alt="">
          </div>
          </div>
          <template v-for="(speaker, index) in normalizedRangeData">
            <div :key="index" class="range-image" :style="getImageStyle(normalizedRangeData.filter(x => x.toPoliticianID === speaker.toPoliticianID)[0])">
              <div class="logo-container">
                <img :src="getImage(speaker.toPoliticianID)" alt="">
            </div>
                <div class="info-card">
                  <div class="info-card-party-logo">
                    <PartyLogo :party="speaker.partyID" :size="'small'" />
                  </div>
                  <span>{{speaker.toPerson}} . {{speaker.percentage.toFixed(1)}}%</span>
                </div>
              </div>
          </template>
      </template>
    </div>
    <div class="chart-description">
      <div class="desc">{{scaleStart}}</div>
      <div class="desc">{{scaleEnd}}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.horizontal-range-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .chart-description {
    position: absolute;
    bottom: -50%;
    display: flex;
    justify-content: space-between;
    width: 90%;
    .desc {
      font-family: "futura-pt-condensed", sans-serif;
      font-weight: 800;
      text-transform: uppercase;
    }
  }
  .line {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 2px;
    width: 90%;
    background: $color-grey;
  }
  .images-container {
    position: relative;
    height: 100%;
    width: 90%;
  }
  .range-image {
    position: absolute;
    transition: 0.3s;
    transform: translate(-50%, -50%);
    width: 10%;
    padding-top: 10%;
    background: white;
    border-radius: 50%;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
    &.base-image {
      top: 50%;
      left: 0;
      width: 16%;
      padding-top: 16%;
    }
    .logo-container {
      position: absolute;
      border-radius: 50%;
      overflow: hidden;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .info-card {
      position: absolute;
      top: 110%;
      left: 50%;
      width: 400%;
      height: 80%;
      padding: 5%;
      visibility: hidden;
      background: white;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
      transform: translateX(-50%);
      z-index: 99;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      pointer-events: none;
      font-family: "futura-pt-condensed", sans-serif;
      font-weight: 800;
      color: grey;
      &.info-card-party {
        justify-content: center;
      }
      &.info-card-vote {
        justify-content: center;
        height: 100%;
      }
      .info-card-party-logo {
        width: 10%;
        background: red;
        // padding-right: 80%;
        // margin-left: 5%;
      }
      span {
        margin: 0;
        margin-left: 10px;
      }
    }
    &:hover {
      z-index: 999;
      .info-card {
        visibility: visible;
      }
    }
  }
}
</style>

<script>
// TODO: if all values (percentages) are the same, then place the parties in the middle of the scale
// [1,1,1,1].every( (val, i, arr) => val === arr[0] )

import parties from "@/assets/parties/parties.json";
import PartyLogo from "@/components/PartyLogo";
import globalConfig from "@/assets/globalConfig.json";
export default {
  props: {
    rangeData: {},
    dataType: {
      default: "parties"
    },
    base: {},
    selectedPeriod: {},
    type: {
      default: "vote"
    }
  },
  components: {
    PartyLogo
  },
  data() {
    return {
      globalConfig,
      parties,
      selectedTopic: 0
    };
  },
  methods: {
    getStaticPoliticianImg: function(speaker) {
      let url =
        this.globalConfig.staticAssetsCDN +
        "/images/" +
        "politicianID_" +
        speaker +
        "_size_240.jpg";
      return url;
    },
    getImage: function(string) {
      if (this.dataType === "parties") {
        return this.partyLogo(string);
      } else {
        return this.getStaticPoliticianImg(string);
      }
    },
    getImageStyle: function(element) {
      let filter = this.dataType === "parties" ? "partyID" : "toPoliticianID";
      if (this.tightImages.indexOf(element[filter]) !== -1) {
        let groupSize = this.tightGroups.filter(
          x => x.map(y => y[filter]).indexOf(element[filter]) !== -1
        )[0].length;
        let elementIndex = this.tightGroups
          .filter(x => x.map(y => y[filter]).indexOf(element[filter]) !== -1)[0]
          .map(y => y[filter])
          .indexOf(element[filter]);
        let heights = {
          2: [20, 50],
          3: [20, 80, 50],
          4: [20, 40, 80, 50],
          5: [30, 50, 10, 90, 70],
          6: [30, 50, 10, 90, 70, 20],
          7: [30, 50, 10, 90, 70, 20, 50],
          8: [30, 50, 10, 90, 70, 20, 50, 90],
          9: [30, 50, 10, 90, 70, 20, 50, 90, 10]
        };
        let elementHeight = heights[groupSize][elementIndex];
        return `left: ${100 - element.percentage}%; top: ${elementHeight}%`;
      } else {
        return `left: ${100 - element.percentage}%; top: 50%`;
      }
    }
  },
  computed: {
    normalizedRangeData: function() {
      let copy = JSON.parse(JSON.stringify(this.rangeData));
      let rangeMin = copy.reduce((prev, curr) => {
        if (prev.percentage > curr.percentage) {
          return curr;
        } else {
          return prev;
        }
      });
      let copy2 = JSON.parse(JSON.stringify(this.rangeData));
      return copy2
        .map(x => {
          let percentage = x.percentage;
          x.percentage =
            ((percentage - rangeMin.percentage) / (110 - rangeMin.percentage)) *
            110;
          return x;
        })
        .sort((a, b) => a.percentage - b.percentage);
    },
    sortedElements: function() {
      let elementsCopy = JSON.parse(JSON.stringify(this.normalizedRangeData));
      return elementsCopy.sort((a, b) => a.percentage - b.percentage);
    },
    tightGroups: function() {
      let result = [];
      for (let i = 0; i < this.sortedElements.length - 1; i++) {
        let currentElement = this.sortedElements[i];
        let nextElement = this.sortedElements[i + 1];
        if (nextElement.percentage - currentElement.percentage < 8) {
          if (
            result.length > 0 &&
            result[result.length - 1].indexOf(currentElement) !== -1
          ) {
            result[result.length - 1].push(nextElement);
          } else {
            result.push([currentElement, nextElement]);
          }
        }
      }
      return result;
    },
    tightImages: function() {
      if (this.dataType === "parties") {
        return [].concat.apply([], this.tightGroups).map(x => x.partyID);
      } else {
        return [].concat.apply([], this.tightGroups).map(x => x.toPoliticianID);
      }
    },
    scaleStart: function() {
      if (this.type === "vote") {
        return "Meget enig";
      } else {
        return "Meget ens";
      }
    },
    scaleEnd: function() {
      if (this.type === "vote") {
        return "Meget uenig";
      } else {
        return "Meget forskellige";
      }
    }
  }
};
</script>

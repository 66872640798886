<template>
  <div class="dropdown-container">
    <div class="dropdown dropdown-bar" @click="showTopicSelect = !showTopicSelect">
      <p class="dropdown-bar-text">{{selectedTopicID === 99 ?"Alle emner":topicsList.filter(x => x.topicID ===selectedTopicID)[0].topicName}}</p>
      <img class="dropdown-bar-arrow" src="@/assets/icons/down-black.svg" alt="">
    </div>
      <transition name="fade">
        <div class="dropdown dropdown-content" v-if="showTopicSelect">
          <div class="dropdown-two-column-container">
            <template v-for="topic in topicsList.filter(x => x.topicID !== 99)">
              <div class="dropdown-topic-name" :key="topic.topicID" @click="selectTopic(topic.topicID)">
                <p class="option-text">{{topic.topicName}}</p>
              </div>
            </template>
          </div>
          <div class="dropdown-single-column-container">
            <div class="dropdown-separator-container">
              <div class="dropdown-separator-line"></div>
            </div>
            <div class="dropdown-all-topics" :key="'All'" @click="selectTopic(99)">
              <p class="option-text">Alle emner</p>
            </div>
          </div>
        </div>
      </transition>

    </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.dropdown-container {
  width: 100%;
  height: 100%;
  background: white;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
}
.dropdown-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  .dropdown-bar-text {
    width: 100%;
    font-size: 120%;
    font-weight: 700;
    color: grey;
    margin: 0;
    font-family: "futura-pt", sans-serif;
    text-align: center;
  }
  .dropdown-bar-arrow {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    height: 30%;
  }
}
.dropdown-content {
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  height: 400%;
  background: white;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
  padding: 1% 2%;
  padding-top: 5%;
  box-sizing: border-box;
  .dropdown-two-column-container {
    height: (calc(100% / 14 * 12));
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: space-around;
    .dropdown-topic-name {
      cursor: pointer;
      width: 45%;
      height: calc(100% / 12);
      color: grey;
      &:hover {
        color: black;
      }
    }
  }
  .option-text {
    font-family: "futura-pt-condensed", sans-serif;
    font-size: 85%;
    line-height: 90%;
    font-weight: 700;
    margin: 0;
    text-align: left;
  }
  .dropdown-single-column-container {
    height: (calc(100% / 14 * 2));
    display: flex;
    flex-direction: column;
    .dropdown-separator-container,
    .dropdown-all-topics {
      height: 50%;
      width: 100%;
    }
    .dropdown-separator-container {
      display: flex;
      justify-content: center;
      align-items: center;
      .dropdown-separator-line {
        width: 90%;
        height: 1px;
        background: grey;
      }
    }
    .dropdown-all-topics {
      cursor: pointer;
      color: grey;
      &:hover {
        color: black;
      }
      p {
        text-align: center;
      }
    }
  }
}
@media (max-width: $bp-narrow) {
  .dropdown-content {
    width: 180%;
    height: 790%;
    left: 50%;
    transform: translateX(-50%);
  }
  .option-text {
    font-size: 140% !important;
  }
}
</style>

<script>
export default {
  props: ["selectedTopicID", "topicsList"],
  data() {
    return {
      showTopicSelect: false
    };
  },
  methods: {
    selectTopic(topicID) {
      this.$eventBus.$emit("selectedTopic", topicID);
      this.showTopicSelect = false;
    }
  }
};
</script>

<template>
  <div class="words-container">
    <div class="words-title">
      <h3>
        <span>NÅR </span>
        <span v-if="subject.isParty">POLITIKERE FRA </span>
        <span v-if="subject.isParty" class="highlight-subject">{{subject.name.toUpperCase()}}</span>
        <span v-else class="highlight-subject">{{subject.name.toUpperCase()}}</span>
        <span> TALER OM </span>
        <span class="highlight-subject">{{selectedTopicNameFormatted.toUpperCase()}},</span>
        <span> ER DER HØJ SANDSYNLIGHED, FOR AT MINDST ET AF DISSE ORD BLIVER NÆVNT</span>
      </h3>
    </div>
    <transition name="fade" mode="out-in">
      <div class="words-list" :key="subject.name + selectedTopicName" v-if="words.length>0">
        <template v-for="word in words.slice(0,20)">
          <div class="word" :key="word">
            <p>{{word}}</p>
          </div>
        </template>
      </div>
      <div v-else>Der er ingen resultater for {{subject.name}}.</div>
    </transition>
  </div>

</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.words-container {
  width: 100%;
  height: 100%;
}
.words-title {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 5%;
  .highlight-subject {
    color: $color-blue;
  }
  h3 {
    font-family: "futura-pt-condensed";
    font-weight: 800;
    font-size: 220%;
    line-height: 120%;
    margin: 0;
  }
}
.words-list {
  position: relative;
  width: 100%;
  height: 20%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .word {
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    font-size: 150%;
    background: $color-blue;
    color: white;
    padding: 1% 2%;
    box-sizing: border-box;
    margin: 1% 1%;
    border-radius: 5%;
    display: flex;
    align-items: center;
    p {
      margin: 0;
    }
  }
}
</style>

<script>
import parties from "@/assets/parties/parties.json";
export default {
  props: ["subject", "words", "selectedTopicName", "isParty"],
  data() {
    return {
      parties
    };
  },
  computed: {
    selectedTopicNameFormatted: function() {
      if (this.selectedTopicName === "All") {
        return "alle emner";
      }
      return this.selectedTopicName;
    }
  }
};
</script>

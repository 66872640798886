<template>
  <Section>
    <HalfSection>
      <SectionLeft :partyID="partyID" :sectionData="adjustedPartySpeakers" :partyView="true" @selectedSpeaker="setSelectedSpeaker" :speakerWordsMode="true" />
    </HalfSection>
    <HalfSection>
      <SectionRight :partyID="partyID" :selectedTopicID="selectedTopicID" :speakerMode="speakerMode" :selectedSpeaker="selectedSpeaker" :sectionData="sectionData.govPeriods[selectedPeriod]" />
    </HalfSection>
  </Section>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
</style>

<script>
import Section from "@/components/Section";
import HalfSection from "@/components/HalfSection";
import SectionLeft from "@/sections/SectionSpeakers/SectionRight";
import SectionRight from "./SectionRight";
export default {
  props: ["sectionData", "partyID", "partySpeakers"],
  components: {
    Section,
    HalfSection,
    SectionLeft,
    SectionRight
  },
  data() {
    return {
      speakerMode: false,
      selectedSpeaker: null,
      selectedPeriod: "allPeriods",
      selectedTopicID: 99
    };
  },
  methods: {
    setSelectedSpeaker: function(event) {
      if (event === null) {
        this.speakerMode = false;
        this.selectedSpeaker = null;
      } else {
        this.speakerMode = true;
        this.selectedSpeaker = event;
      }
    },
    setSelectedPeriod: function(event) {
      this.selectedPeriod = event;
    },
    setSelectedTopic: function(event) {
      this.selectedTopicID = event;
    }
  },
  computed: {
    adjustedPartySpeakers: function() {
      let newSpeakers = {
        govPeriods: {}
      };
      for (let period in this.partySpeakers.govPeriods) {
        newSpeakers.govPeriods[period] = {};
        newSpeakers.govPeriods[period][
          "speakers"
        ] = this.partySpeakers.govPeriods[period].speakers.map(x => {
          x["partyID"] = this.partyID;
          return x;
        });
      }
      return newSpeakers;
    }
  },
  created() {
    this.$eventBus.$on("selectedPeriod", this.setSelectedPeriod);
    this.$eventBus.$on("selectedTopic", this.setSelectedTopic);
  },
  beforeDestroy() {
    this.$eventBus.$off("selectedPeriod");
    this.$eventBus.$off("selectedTopic");
  }
};
</script>

<template>
  <TitleText :background="'turquoise'" :whiteText="true">
    <template slot="title">VENNER OG FJENDER</template>
    <template slot="subtitle">
      Se, hvem {{parties[partyID].name}} stemmer mest sammen med for hver periode.
      <br>
      <br>
      I Danmark er der en tradition for at lave brede aftaler, og det betyder, at partierne generelt stemmer det samme. Men der er stadig store forskelle på, hvilke partier der minder meget om hinanden &ndash; og det kan også ændre sig meget på forskellige områder og over tid.
    </template>
  </TitleText>

</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
span.inverse {
  background: #fff;
  color: $color-turquoise;
  padding: 1px 3px 1px 3px;
  font-weight: bold;
}
</style>

<script>
import TitleText from "@/components/TitleText";
import parties from "@/assets/parties/parties.json";
export default {
  props: ["partyID"],
  components: {
    TitleText
  },
  data() {
    return {
      parties
    };
  }
};
</script>

<template>
    <Section>
        <HalfSection>
            <SectionLeft :partyID="partyID" />
        </HalfSection>
        <HalfSection>
            <SectionRight :partyID="partyID" />
        </HalfSection>
    </Section>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
</style>

<script>
import Section from "@/components/Section";
import HalfSection from "@/components/HalfSection";
import SectionLeft from "./SectionLeft";
import SectionRight from "./SectionRight";

export default {
  props: ["partyID"],
  components: {
    Section,
    HalfSection,
    SectionLeft,
    SectionRight
  }
};
</script>

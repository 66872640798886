<template>
  <div class="outer-container">
    <div class="inner-square">
        <div class="text-container">
            <h1 class="title">Hvilke emner bruger <span class="highlight">{{parties[partyID].name}}</span> mest tid på i folketingssalen?</h1>
            <template v-if="selectedPeriod !== 'allPeriods'">
              <p class="subtitle">Oversigt over, hvilke politiske emner {{parties[partyID].name}}s politikere har brugt længst tid på at tale om i folketingssalen i den valgte periode ({{periodDates()[0]}} - {{periodDates()[1]}}).</p> 
              <p class="subtitle">Er det overraskende at det er {{parties[partyID].name}}s top emner? Husk at du også kan skifte regeringsperiode.</p>
            </template>
            <template v-else>
              <p class="subtitle">Oversigt over hvilke politiske emner {{parties[partyID].name}}s politikere har brugt længst tid på at tale om i folketingssalen i alle de valgte perioder.</p>
              <p class="subtitle">Er det overraskende, at det er {{parties[partyID].name}}s topemner? Husk, at du også kan skifte regeringsperiode.</p>
            </template>
        </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.outer-container {
  background: white;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.inner-square {
  position: relative;
  background: $color-orange;
  width: 67%;
  height: 90%;
  z-index: 10;
}
.text-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-weight: 900;
    font-size: 300%;
    color: white;
    .highlight {
      // color: $color-blue;
      color: #000;
    }
  }
  .subtitle {
    font-size: 100%;
    color: #fff;
    text-align: left;
  }
  h4 {
    color: #fff;
  }
  span.inverse {
    background: #fff;
    color: #000;
    padding: 1px 3px 1px 3px;
    font-weight: bold;
  }
}
</style>

<script>
import parties from "@/assets/parties/parties.json";
import axios from "axios";

export default {
  props: ["partyID", "selectedPage"],
  components: {},
  data() {
    return {
      parties,
      periods: null,
      selectedPeriod: "allPeriods"
    };
  },
  methods: {
    setSelectedPeriod: function(event) {
      this.selectedPeriod = event;
    },
    periodDates: function() {
      if (this.periods !== null && this.selectedPeriod !== "allPeriods") {
        let periodData = this.periods.filter(
          x => x.periodID === this.selectedPeriod
        )[0];
        let startdate, enddate;
        startdate = periodData.startDate.slice(0, 4);
        if (
          periodData.endDate == null
            ? (enddate = 2018)
            : (enddate = periodData.endDate.slice(0, 4))
        )
          return [startdate, enddate];
      } else {
        return "";
      }
    }
  },
  watch: {},
  mounted() {
    let self = this;
    axios
      .get("/static/periods_new.json")
      .then(response => (this.periods = response.data));
  },
  created() {
    this.$eventBus.$on("selectedPeriod", this.setSelectedPeriod);
  },
  beforeDestroy() {
    this.$eventBus.$off("selectedPeriod");
  }
};
</script>

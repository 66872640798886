<template>
  <TitleText :background="'lightgreen'">
    <template slot="title">PARTIETS REPRÆSENTANTER</template>
    <template slot="subtitle">
      <p>{{parties[partyID].name}} havde flest medlemmer af Folketinget i perioden {{maxPeriodDates}} med {{maxPeriodMandates}} mandater, hvilket svarer til {{(maxPeriodMandates/179*100).toFixed(1).replace('.', ',')}} % af de 179 mandater i Folketinget.</p>
    </template>
  </TitleText>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
span.inverse {
  background: #000;
  color: $color-lightgreen;
  padding: 1px 3px 1px 3px;
  font-weight: bold;
}
</style>

<script>
import axios from "axios";
import TitleText from "@/components/TitleText";
import parties from "@/assets/parties/parties.json";

export default {
  props: ["speakerStats", "partyID"],
  components: {
    TitleText
  },
  data() {
    return {
      parties,
      periods: null
    };
  },
  computed: {
    mandates: function() {
      let self = this;
      if (this.periods) {
        if (this.speakerStats.currentPeriod == "allPeriods") {
          let start = 0;
          let end = 4;
          if (this.partyID === "ALT") start = 2;
          let size = 0;
          let sum = 0;
          let result = this.periods.slice(start, end).map(function(a, index) {
            size = index;
            sum += a.partyMandates[self.partyID];
          });
          return sum / (size + 1);
        } else {
          return speakerStats.currentPeriodMandates;
        }
      }
    },
    maxPeriodDates: function() {
      if (this.periods) {
        let currentPeriod = this.periods.filter(
          x => x.periodID === this.speakerStats.maxPeriod
        )[0];
        let startYear = currentPeriod.startDate.slice(0, 4);
        let endYear = currentPeriod.endDate
          ? currentPeriod.endDate.slice(0, 4)
          : new Date().getFullYear();
        return `${startYear}-${endYear}`;
      } else {
        return null;
      }
    },
    maxPeriodMandates: function() {
      if (this.periods) {
        let currentPeriod = this.periods.filter(
          x => x.periodID === this.speakerStats.maxPeriod
        )[0];
        let mandates = currentPeriod.partyMandates[this.partyID];
        return mandates;
      } else {
        return null;
      }
    },
    currentPeriodDates: function() {
      if (this.periods) {
        if (this.speakerStats.currentPeriod === "allPeriods") {
          return "Siden 2011 har partiet i gennemsnit haft ";
        } else {
          let currentPeriod = this.periods.filter(
            x => x.periodID === this.speakerStats.currentPeriod
          )[0];
          let startYear = currentPeriod.startDate.slice(0, 4);
          let endYear = currentPeriod.endDate
            ? currentPeriod.endDate.slice(0, 4)
            : new Date().getFullYear();
          return `I valgte periode ${startYear}-${endYear} har partiet `;
        }
      } else {
        return null;
      }
    }
  },
  mounted() {
    axios.get(`/static/periods.json`).then(response => {
      this.periods = response.data;
    });
  }
};
</script>

<template>
  <div class="wrapper">
    <div class="container">
      <h4>Antal minutter, {{parties[partyID].name}}s politikere har talt om forskellige politiske emner</h4>
      <div class="bar-chart-holder">
        <HorizontalBarChart v-if="data" :data="data" type="party" :selectedPeriod="selectedPeriod"></HorizontalBarChart>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.wrapper {
  background: $color-lightblue;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  width: 100%;
  .bar-chart-holder {
    position: relative;
    width: 90%;
    height: 60%;
  }
  .title {
    text-transform: uppercase;
  }
  h4 {
    font-size: 150%;
    text-align: center;
    font-family: "futura-pt-condensed";
  }
  h4:first-letter {
    text-transform: capitalize;
  }
}
</style>

<script>
import axios from "axios";
import parties from "@/assets/parties/parties.json";
import HorizontalBarChart from "@/components/HorizontalBarChart";

export default {
  props: ["partyID", "selectedPage"],
  components: {
    HorizontalBarChart
  },
  data() {
    return {
      parties,
      data: null,
      selectedPeriod: "periodAll",
      generalStats: null
    };
  },
  methods: {
    setSelectedPeriod: function(event) {
      this.selectedPeriod = event;
    }
  },
  computed: {
    routeParameter: function() {
      return this.$route.params.partyID;
    }
  },
  created() {
    this.$eventBus.$on("selectedPeriod", this.setSelectedPeriod);
    let self = this;
    axios
      .get("/static/parties/party_" + self.partyID + ".json")
      .then(function(response) {
        self.generalStats = response.data.PartyTopics.govPeriods;
        self.data = self.generalStats;
      });
  },
  beforeDestroy() {
    this.$eventBus.$off("selectedPeriod");
  },
  watch: {
    routeParameter: function() {
      let self = this;
      axios
        .get("/static/parties/party_" + self.partyID + ".json")
        .then(function(response) {
          self.generalStats = response.data.PartyTopics.govPeriods;
          self.data = self.generalStats;
        });
    }
  }
};
</script>

<template>
  <div class="page-container">
    <div class="dropdown-area">
      <TopicSelect :topicsList="topicsList" :selectedTopicID="selectedTopicID" />
    </div>
    <TopWords :subject="subject" :isParty="selectedSpeaker?true:false" :selectedTopic="selectedTopicID" :selectedTopicName="selectedTopicName" :words="topicWords" />
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.page-container {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
}
.dropdown-area {
  position: absolute;
  top: 4%;
  left: 50%;
  transform: translateX(-50%);
  width: 54.5%;
  height: 8%;
  z-index: 99;
}
</style>


<script>
import axios from "axios";
import globalConfig from "@/assets/globalConfig.json";
import parties from "@/assets/parties/parties.json";
import TopicSelect from "@/components/TopicSelect";
import TopWords from "@/components/TopWords";
export default {
  props: {
    partyID: {},
    sectionData: {},
    selectedSpeaker: {
      default: null
    },
    selectedPeriod: {},
    selectedTopicID: {}
  },
  components: {
    TopicSelect,
    TopWords
  },
  data() {
    return {
      globalConfig,
      parties,
      speakerWords: null
    };
  },
  methods: {
    getSpeakerWords: function() {
      let topic = this.selectedTopicID;
      if (topic === 99) {
        topic = 999;
      }
      let period = 999;
      if (this.selectedPeriod !== "allPeriods") {
        period = Number(this.selectedPeriod.slice(6, 7)) + 2;
      }
      let url =
        this.globalConfig.apiBaseUrl +
        `/speaker/top_words/${
          this.selectedSpeaker.politicianID
        }/${period}/${topic}`;
      axios
        .get(url)
        .then(response => (this.speakerWords = response.data.response));
    }
  },
  computed: {
    topicsList: function() {
      return this.sectionData.map(x => {
        let response = {
          topicID: x.topicID,
          topicName: x.topicName
        };
        return response;
      });
    },
    selectedTopicName: function() {
      return this.topicsList.filter(x => x.topicID === this.selectedTopicID)[0]
        .topicName;
    },
    topicWords: function() {
      if (this.speakerWords === null) {
        return this.sectionData.filter(
          x => x.topicID === this.selectedTopicID
        )[0].topWords;
      } else {
        return this.speakerWords;
      }
    },
    subject: function() {
      if (this.speakerWords === null) {
        return {
          name: this.parties[this.partyID].name,
          isParty: true
        };
      } else {
        return {
          name: this.selectedSpeaker.politician,
          isParty: false
        };
      }
    }
  },
  watch: {
    selectedSpeaker: function() {
      if (this.selectedSpeaker !== null) {
        this.getSpeakerWords();
      } else {
        this.speakerWords = null;
      }
    },
    selectedPeriod: function() {
      if (this.selectedSpeaker !== null) {
        this.getSpeakerWords();
      } else {
        return;
      }
    },
    selectedTopicID: function() {
      if (this.selectedSpeaker !== null) {
        this.getSpeakerWords();
      } else {
        return;
      }
    }
  }
};
</script>

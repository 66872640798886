<template>

  <div class="outer-container">
    <MultiPageBox :pages="selectedSpeaker === null ? 4 : 2" :background="''" :selectPage="selectPage" :animate="true">

      <template slot="page-1">
        <Page1 :partyID="partyID" :selectedTopicID="selectedTopicID" :sectionData="sectionData" :selectedSpeaker="selectedSpeaker" :selectedPeriod="selectedPeriod" />
      </template>

      <template slot="page-2">
        <Page2 :partyID="partyID" :selectedTopicID="selectedTopicID" :sectionData="sectionData" :selectedSpeaker="selectedSpeaker" :visibleElements="'start'" :selectedPeriod="selectedPeriod" />
      </template>

      <template slot="page-3">
        <Page2 :partyID="partyID" :selectedTopicID="selectedTopicID" :sectionData="sectionData" :selectedSpeaker="selectedSpeaker" :visibleElements="'middle'" :selectedPeriod="selectedPeriod" />
      </template>

      <template slot="page-4">
        <Page2 :partyID="partyID" :selectedTopicID="selectedTopicID" :sectionData="sectionData" :selectedSpeaker="selectedSpeaker" :visibleElements="'all'" :selectedPeriod="selectedPeriod" />
      </template>

    </MultiPageBox>

  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.outer-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  background: $color-lightgreen;
}
</style>

<script>
import axios from "axios";
import MultiPageBox from "@/components/MultiPageBox";
import Page1 from "./Page1";
import Page2 from "./Page2";
export default {
  props: [
    "partyID",
    "speakerMode",
    "sectionData",
    "selectedSpeaker",
    "selectedTopicID"
  ],
  components: {
    MultiPageBox,
    Page1,
    Page2
  },
  data() {
    return {
      selectedPeriod: "allPeriods",
      selectPage: null,
      periods: null
    };
  },
  methods: {
    setSelectedPeriod: function(event) {
      this.selectedPeriod = event;
    }
  },
  watch: {
    speakerMode: function() {
      // horrible hack to reset to page 1 in MultiPageBox without extra props
      if (this.speakerMode) {
        this.selectPage = null;
      } else {
        this.selectPage = 1;
      }
    }
  },
  created() {
    this.$eventBus.$on("selectedPeriod", this.setSelectedPeriod);
    axios
      .get("/static/periods.json")
      .then(response => (this.periods = response.data));
  },
  beforeDestroy() {
    this.$eventBus.$off("selectedPeriod");
  }
};
</script>

<template>
    <TitleText :background="'blue'" :whiteText="true">
        <template slot="title">{{parties[partyID].name}}</template>
        <template slot="subtitle">
            <template v-if="selectedPeriod !== 'allPeriods'">
                <p>{{parties[partyID].name}} har i denne periode ({{periodDates()[0]}} - {{periodDates()[1]}}) {{partyMandates()}} medlemmer af folketinget og er det {{mandatePlacement()}} største parti målt på mandater. </p>
            </template>
            <template v-else>
                <p>I gennemsnit har {{parties[partyID].name}} haft {{mandatesAverage}} medlemmer af Folketinget i alle de valgte perioder.</p>
            </template>
            <p>Den 1. januar 2016 var {{parties[partyID].name}} med sine {{partyMembership[partyID].members.toLocaleString('da-DK')}} medlemmer det {{partyMembership[partyID].pos}}største parti målt på antal medlemmer.</p>
        </template>
    </TitleText>

</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
span.inverse {
  background: #fff;
  color: $color-turquoise;
  padding: 1px 3px 1px 3px;
  font-weight: bold;
}
</style>

<script>
import TitleText from "@/components/TitleText";
import parties from "@/assets/parties/parties.json";
import axios from "axios";

export default {
  props: ["partyID"],
  components: {
    TitleText
  },
  data() {
    return {
      selectedPeriod: "allPeriods",
      periods: null,
      parties,
      partyPlacement: {
        1: "",
        2: "nest",
        3: "tredje",
        4: "fjerde",
        5: "femte",
        6: "sjette",
        7: "syvende",
        8: "ottende",
        9: "niende"
      },
      partyMembership: {
        V: { members: 35957, pos: "nest" },
        S: { members: 40060, pos: "" },
        RV: { members: 7000, pos: "ottende" },
        KF: { members: 9402, pos: "femte" },
        SF: { members: 8131, pos: "syvende" },
        DF: { members: 15911, pos: "tredje" },
        EL: { members: 9335, pos: "sjette" },
        LA: { members: 6000, pos: "niende" },
        ALT: { members: 10665, pos: "fjerde" }
      }
    };
  },
  methods: {
    setSelectedPeriod: function(event) {
      this.selectedPeriod = event;
      //let data = JSON.parse(JSON.stringify(this.periods));
    },
    partyMandates: function() {
      if (this.periods !== null && this.selectedPeriod !== "allPeriods") {
        let periodData = this.periods.filter(
          x => x.periodID === this.selectedPeriod
        )[0];
        return periodData.partyMandates[this.partyID];
      } else {
        // console.log("null?");
        return "";
      }
    },
    periodDates: function() {
      if (this.periods !== null && this.selectedPeriod !== "allPeriods") {
        let periodData = this.periods.filter(
          x => x.periodID === this.selectedPeriod
        )[0];
        let startdate, enddate;
        startdate = periodData.startDate.slice(0, 4);
        if (
          periodData.endDate == null
            ? (enddate = 2018)
            : (enddate = periodData.endDate.slice(0, 4))
        )
          return [startdate, enddate];
      } else {
        return "";
      }
    },
    mandatePlacement: function() {
      if (this.periods !== null && this.selectedPeriod !== "allPeriods") {
        let periodData = this.periods.filter(
          x => x.periodID === this.selectedPeriod
        )[0];
        let partyMandateValue = periodData.partyMandates[this.partyID];
        let arr = Object.values(periodData.partyMandates);
        let arrsorted = arr.sort(function(a, b) {
          return b - a;
        });
        let placement = arrsorted.indexOf(partyMandateValue) + 1;
        return this.partyPlacement[placement];
      } else {
        return "";
      }
    }
  },
  computed: {
    mandatesAverage: function() {
      let self = this;
      if (this.periods !== null && this.selectedPeriod == "allPeriods") {
        let start = 0;
        let end = 4;
        if (this.partyID === "ALT") start = 2;
        let size = 0;
        let sum = 0;
        let result = this.periods.slice(start, end).map(function(a, index) {
          size = index;
          sum += a.partyMandates[self.partyID];
        });
        return sum / (size + 1).toFixed(0);
      } else {
        return " ";
      }
    }
  },
  created() {
    this.$eventBus.$on("selectedPeriod", this.setSelectedPeriod);
  },
  mounted() {
    let self = this;
    axios
      .get("/static/periods_new.json")
      .then(response => (this.periods = response.data));
  },
  beforeDestroy() {
    this.$eventBus.$off("selectedPeriod");
  }
};
</script>

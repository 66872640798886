<template>
  <div class="wrapper">
    <MultiPageBox :pages="pages" :animate="true">
      <template slot="page-1">
        <div class="inner-square">
          <div class="text-container">
            <div class="slide" v-bind:class="[{ active: slides[0] }, {hidden: !slides[0]}]">
              <h2 class="title">Fact #1: Målt på medlemstal er {{parties[partyID].name}} det {{membershipPlacement[partyID]}}største danske parti</h2>
              <div class="bar-chart-holder">
                <MultiLineChart chartname="stats_parties_membership"></MultiLineChart>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="page-2">
        <div class="inner-square">
          <div class="text-container">
            <div class="slide" v-bind:class="[{ active: slides[1] }, {hidden: !slides[1]}]">
              <h2 class="title">Fact #2: {{parties[partyID].name}} har den {{averageAgePlacement}}yngste partigruppe i Folketinget</h2>
              <div class="bar-chart-holder">
                <BarChart :data="stats" :xaxis_image="true" chartname="stats_parties_avg_age" yaxis_start="40" yaxis_end="5" :selected_party="partyID" yaxis_label="Gennemsnitsalder" :key="partyID"></BarChart>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="page-3" v-if="partyID !== 'EL'">
        <div class="inner-square">
          <div class="text-container">
            <div class="slide" v-bind:class="[{ active: slides[2] }, {hidden: !slides[2]}]">
              <template v-if="selectedPeriod !== 'allPeriods'">
                <img class="politician-leader" :src="getStaticPoliticianImg(getPartyLeader())" />
                <h1 class="title">{{getPartyLeader().name}}</h1>
                <p class="subtitle">Formand for {{parties[partyID].name}}</p>
              </template>
              <template v-else>
                <h1 class="title">Formænd for {{parties[partyID].name}}</h1>
                <div class="speakers">
                  <template v-for="(speaker, index) in getPartyLeader()">
                    <div :key="speaker.politicianId" class="speaker">
                      <div class="speaker-image">
                        <img :src="getStaticPoliticianImg(speaker)" alt="" w="100%">
                        <p v-if="periods">
                          {{speaker.name.toUpperCase()}} <br />
                          {{periods[index].startDate.slice(0,4)}} - <span v-if="periods[index].endDate">{{periods[index].endDate.slice(0,4)}}</span>
                        </p>
                      </div>
                    </div>
                  </template>

                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
      <!-- <template slot="page-4">
        <div class="inner-square">
          <div class="text-container">
            <div class="slide" v-bind:class="[{ active: slides[3] }, {hidden: !slides[3]}]">
              <h2 class="title">{{parties[partyID].name}} har en kønsbalance på xx% mænd og xx% kvinder</h2>
            </div>          
          </div>
        </div>
      </template> -->
      <!-- <template slot="page-4">
        <div class="inner-square">
          <div class="text-container">
            <div class="slide" v-bind:class="[{ active: slides[3] }, {hidden: !slides[3]}]">
              <h1 class="title">Om partiet</h1>
              <PartyAbout :partyID="partyID"></PartyAbout>
            </div>
          </div>
        </div>
      </template>             -->

    </MultiPageBox>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.wrapper {
  background: white;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.outer-container {
  background: white;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.inner-square {
  position: relative;
  width: 100%;
  height: 100%;
}
.text-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  .slide {
    width: 100%;
    height: 100%;
    .bar-chart-holder {
      position: relative;
      width: 90%;
      height: 60%;
    }
  }
  .title {
    font-weight: 900;
    font-size: 300%;
    color: 000;
  }
  img.politician-leader {
    width: 70%;
    height: auto;
    filter: grayscale(80%);
    &:hover {
      filter: grayscale(50%);
    }
  }
  h1 {
    margin: 0;
  }
  h2.title {
    font-family: "futura-pt-condensed";
    font-size: 120%;
    text-transform: uppercase;
    margin: 0;
  }
  p.subtitle {
    font-family: "futura-pt-condensed";
    padding: 0;
    margin: 0;
    font-size: 180%;
    color: #000;
    text-transform: uppercase;
  }
}
.speakers {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  .speaker {
    display: inline-block;
    position: relative;
    width: 35%;
    height: 35%;
    margin: -5% 3% 15% 3%;
    box-sizing: border-box;
    transition: 0.3s;
    perspective: 1000px;
    &.speaker-highlighted::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 10px solid $color-lightgreen;
      z-index: 9;
      pointer-events: none;
    }
    &.speaker-hidden {
      opacity: 0.4;
    }
    .speaker-name-card {
      position: absolute;
      bottom: -18%;
      right: -8%;
      background: $color-lightgrey;
      width: 118%;
      height: 27%;
      opacity: 0;
      transition: opacity 0.2s;
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        font-family: "futura-pt";
        font-weight: 700;
        margin: 0;
        color: black;
      }
      .speaker-party-name {
        color: $color-darkgrey;
      }
    }
    .speaker-name-card-bg {
      position: absolute;
      bottom: -20%;
      right: -10%;
      width: 118%;
      height: 27%;
      background: $color-grey;
      opacity: 0;
      z-index: 5;
    }
    &:hover {
      .speaker-name-card,
      .speaker-name-card-bg {
        opacity: 1;
      }
    }
    .party-logo {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5%;
      z-index: 99;
    }
    .speaker-image,
    .speaker-image-disabled,
    .speaker-background {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .speaker-image {
      z-index: 2;
      background: $color-lightgrey;
      img {
        display: block;
        width: 100%;
        filter: grayscale(80%);
        &:hover {
          filter: grayscale(50%);
        }
      }
    }
    .speaker-image-disabled {
      z-index: 2;
      background: $color-lightgrey;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 50%;
        opacity: 0.2;
      }
    }
    .speaker-background {
      background: $color-grey;
      top: 5%;
      left: 5%;
      z-index: 1;
    }
    .speaker-party-logo {
      position: absolute;
      top: -5%;
      right: -5%;
      width: 20%;
      height: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
      transition: opacity 0.3s;
    }
    &:hover {
      .speaker-party-logo {
        opacity: 1;
      }
    }
  }
}
</style>

<script>
import globalConfig from "@/assets/globalConfig.json";
import axios from "axios";
import parties from "@/assets/parties/parties.json";
import across_parties from "@/assets/parties/across_parties.json";
import MultiPageBox from "@/components/MultiPageBox";
import BarChart from "@/components/BarChart";
import MultiLineChart from "@/components/MultiLineChart";
// import PartyAbout from "./PartyAbout";

export default {
  props: ["partyID"],
  components: {
    MultiPageBox,
    BarChart,
    MultiLineChart
    // PartyAbout
  },
  data() {
    return {
      parties,
      globalConfig,
      across_parties,
      selectedPeriod: "allPeriods",
      periods: null,
      pages: 3,
      slides: [true, false, false],
      dataStructure: [],
      partyPlacement: {
        1: "",
        2: "nest",
        3: "tredje",
        4: "fjerde",
        5: "femte",
        6: "sjette",
        7: "syvende",
        8: "ottende",
        9: "niende"
      },
      membershipPlacement: {
        S: "",
        V: "nest",
        DF: "tredje",
        ALT: "fjerde",
        KF: "femte",
        EL: "sjette",
        SF: "syvende",
        RV: "ottende",
        LA: "niende"
      }
    };
  },
  methods: {
    selectPolitician: function(speaker) {
      this.selectedSpeaker = speaker;
      this.showPoliticianInfo = true;
    },
    selectParty: function(party) {
      this.selectedParty = party;
      this.showPartyInfo = true;
    },
    partyLogo: function(party) {
      return require(`@/assets/parties/logos/new/${party}_small.png`);
    },
    setSelectedPeriod: function(event) {
      this.selectedPeriod = event;
      this.selectedPage = 1;
      // console.log(this.selectedPeriod);
    },
    setSelectedPage: function(event) {
      this.selectedPage = event;
    },
    getStaticPoliticianImg: function(speaker) {
      let url =
        globalConfig.staticAssetsCDN +
        "/images/politicianID_" +
        speaker.politicianID +
        "_size_500.jpg";
      return url;
    },
    getPartyLeader: function() {
      let self = this;
      if (this.periods !== null && this.selectedPeriod !== "allPeriods") {
        let periodData = this.periods.filter(
          x => x.periodID === this.selectedPeriod
        )[0];
        return periodData.partyLeader[this.partyID];
      } else if (this.periods !== null) {
        let start = 0;
        let end = 4;
        if (this.partyID === "ALT") start = 2;
        let result = this.periods.slice(start, end).map(function(a) {
          if (a.partyLeader[self.partyID].length > 1) {
            return a.partyLeader[self.partyID][0];
          } else {
            return a.partyLeader[self.partyID];
          }
        });
        return result;
      } else {
        return null;
      }
    },
    resetDataStructure: function() {
      // Reset the structure which holds all the parties, so that if a query does not return something, the value is 0
      this.dataStructure = [];
      let self = this;
      Object.keys(parties).forEach(function(key) {
        self.dataStructure.push({ axis: key, count: 0 });
      });
    }
  },
  computed: {
    totalselectedPages: function() {
      return this.pages;
    },
    stats() {
      this.resetDataStructure();
      let parsedData = [];
      for (var item of this.across_parties.PartyInfo.govPeriods[
        this.selectedPeriod
      ]) {
        parsedData.push({ axis: item["partyID"], count: item["avgAge"] });
      }

      var key = "axis";
      let match = this.dataStructure.map(el => {
        var found = parsedData.find(s => s[key] === el[key]);
        if (found) {
          el = Object.assign(el, found);
        }
        return el;
      });
      return match;
    },
    averageAgePlacement() {
      let party = this.across_parties.PartyInfo.govPeriods[
        this.selectedPeriod
      ].find(o => o.partyID === this.partyID);
      var allParties = this.across_parties.PartyInfo.govPeriods[
        this.selectedPeriod
      ].reduce(function(result, item) {
        if (item.partyID !== "UFG") {
          result.push(item.avgAge);
        }
        return result;
      }, []);
      let arrsorted = allParties.sort(function(a, b) {
        return a - b;
      });
      let placement = arrsorted.indexOf(party["avgAge"]) + 1;
      return this.partyPlacement[placement];
    }
  },
  mounted() {
    this.$eventBus.$on("selectedPeriod", this.setSelectedPeriod);
    if (this.partyID === "EL") {
      this.pages = 2;
      this.slides = [true, false];
    }
  },
  created() {
    this.$eventBus.$on("selectedPeriod", this.setSelectedPeriod);
    axios
      .get("/static/periods_new.json")
      .then(response => (this.periods = response.data));
  },
  beforeDestroy() {
    this.$eventBus.$off("selectedPeriod");
  }
};
</script>

<template>
  <Section>
    <HalfSection>
      <SectionLeft :speakerStats="speakerStats" :partyID="partyID" />
    </HalfSection>
    <HalfSection>
      <SectionRight :sectionData="modifiedSectionData" :partyView="true" animate="true" />
    </HalfSection>
  </Section>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
</style>

<script>
import Section from "@/components/Section";
import HalfSection from "@/components/HalfSection";
import SectionLeft from "./SectionLeft";
import SectionRight from "@/sections/SectionSpeakers/SectionRight";
export default {
  props: ["partyID", "sectionData"],
  components: {
    Section,
    HalfSection,
    SectionLeft,
    SectionRight
  },
  data() {
    return {
      selectedPeriod: "period1"
    };
  },
  methods: {
    setSelectedPeriod: function(event) {
      this.selectedPeriod = event;
      this.selectedPage = 1;
    }
  },
  computed: {
    modifiedSectionData: function() {
      if (this.sectionData) {
        let newSpeakers = {
          govPeriods: {}
        };
        for (let period in this.sectionData.govPeriods) {
          newSpeakers.govPeriods[period] = {};
          newSpeakers.govPeriods[period][
            "speakers"
          ] = this.sectionData.govPeriods[period].speakers.map(x => {
            x["partyID"] = this.partyID;
            return x;
          });
        }
        return newSpeakers;
      } else {
        return null;
      }
    },
    speakerStats: function() {
      let self = this;
      if (
        Object.keys(this.modifiedSectionData.govPeriods).indexOf(
          this.selectedPeriod
        ) !== -1
      ) {
        let stats = {};
        stats["currentPeriod"] = this.selectedPeriod;
        stats["currentPeriodMandates"] = this.modifiedSectionData.govPeriods[
          this.selectedPeriod
        ].speakers.length;
        stats["maxPeriod"] = Object.keys(this.modifiedSectionData.govPeriods)
          .filter(x => x !== "allPeriods")
          .reduce(
            (prev, curr) =>
              self.modifiedSectionData.govPeriods[curr].speakers.length >
              self.modifiedSectionData.govPeriods[prev].speakers.length
                ? curr
                : prev,
            "period4"
          );
        stats["maxPeriodMandates"] = this.modifiedSectionData.govPeriods[
          stats.maxPeriod
        ].speakers.length;
        return stats;
      } else {
        return null;
      }
    }
  },
  created() {
    this.$eventBus.$on("selectedPeriod", this.setSelectedPeriod);
  },
  beforeDestroy() {
    this.$eventBus.$off("selectedPeriod");
  }
};
</script>

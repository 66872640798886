<template>
  <div class="page-container">
    <div class="dropdown-area">
      <TopicSelect :topicsList="topicsList" :selectedTopicID="selectedTopicID" />
    </div>
    <template v-if="selectedSpeaker===null">
      <h1 class="title heading" v-if="visibleElements === 'start'">
        SAMMENLIGNER VI
        <span class="highlight">{{parties[partyID].name}}</span> MED ANDRE PARTIER, ER DER IKKE OVERRASKENDE NOGLE, DER TALER MEGET FORSKELLIGT FRA {{parties[partyID].name}}
      </h1>
      <h1 class="title heading" v-else-if="visibleElements === 'middle'">
        MEN DER ER OGSÅ NOGLE DER TALER MERE LIGESOM
        <span class="highlight">{{parties[partyID].name}}</span>.
      </h1>
      <h1 class="title heading" v-else-if="visibleElements === 'all'">
        OG NÅR VI INKLUDERER HELE DET POLITISKE SPEKTRUM, SER BILLEDET SÅDAN UD.
      </h1>
    </template>

    <!-- <transition name="fade" mode="out-in"> -->
    <div class="chart-container" v-if="selectedSpeaker===null">
      <HorizontalRange :rangeData="rangeData" :selectedPeriod="selectedPeriod" :base="selectedSpeaker===null?partyID:selectedSpeaker" :visibleElements="visibleElements" :dataType="selectedSpeaker === null?'parties':'speakers'" type="speech" />
    </div>

    <div class="lists-container" v-if="selectedSpeaker!==null">
      <h1 class="title lists-title">
        DISSE POLITIKERE TALER MEST LIGESOM ELLER ANDERELEDES END
        <span class="highlight">{{selectedSpeaker.politician}}</span>
      </h1>
      <div class="top-list">
        <h2 class="list-title">MEST LIGESOM</h2>
        <template v-for="politician in rangeData.slice(0,5)">
          <div class="list-entry" :key="politician.toPerson">
            <div class="list-image">
              <img :src="getStaticPoliticianImg(politician.toPoliticianID)" alt="">
              <div class="list-party-logo">
                <PartyLogo :party="politician.partyID" :size="'small'" :circle="true" />
              </div>
            </div>
            <p>{{politician.toPerson}}</p>
          </div>
        </template>
      </div>
      <div class="bottom-list">
        <h2 class="list-title">MEST ANDERELEDES</h2>
        <template v-for="politician in rangeData.slice(15,20).reverse()">
          <div class="list-entry" :key="politician.toPerson">
            <div class="list-image">
              <img :src="getStaticPoliticianImg(politician.toPoliticianID)" alt="">
              <div class="list-party-logo">
                <PartyLogo :party="politician.partyID" :size="'small'" :circle="true" />
              </div>
            </div>
            <p>{{politician.toPerson}}</p>
          </div>
        </template>

      </div>
    </div>
    <!-- </transition> -->

  </div>

</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
.dropdown-area {
  position: absolute;
  top: 4%;
  left: 50%;
  transform: translateX(-50%);
  width: 54.5%;
  height: 8%;
  z-index: 99;
}

.page-container {
  width: 90%;
  height: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .heading {
    position: absolute;
    bottom: 54%;
    left: 3%;
    font-size: 250%;
    line-height: 120%;
    .highlight {
      color: $color-blue;
    }
  }
  .chart-container {
    position: relative;
    width: 90%;
    height: 15%;
  }
  .lists-container {
    width: 90%;
    height: 75%;
    // background: pink;
    display: flex;
    flex-wrap: wrap;
    .lists-title {
      width: 100%;
      height: 30%;
      font-size: 250%;
      line-height: 120%;
      margin: 0;
      .highlight {
        color: $color-blue;
      }
    }
    .top-list,
    .bottom-list {
      margin-top: 5%;
      width: 50%;
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 3%;
      box-sizing: border-box;
      .list-title {
        margin: 0;
        text-align: center;
        font-family: "futura-pt-condensed", sans-serif;
        font-weight: 800;
        font-size: 200%;
      }
    }
    .top-list {
      // background: beige;
      border-right: 5px solid black;
    }
    .bottom-list {
      // background: orange;
    }
  }
  .list-entry {
    position: relative;
    height: 15%;
    display: flex;
    align-items: center;
    background: white;
    border-radius: 1px;
    font-family: "futura-pt";
    font-weight: 800;
    .list-image {
      position: relative;
      height: 100%;
      width: auto;
      margin-right: 15px;
      background: grey;
      img {
        height: 100%;
        width: auto;
      }
    }
    .list-party-logo {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 2px;
      right: 0;
      transform: translateX(50%);
    }
  }
}
</style>


<script>
import axios from "axios";
import parties from "@/assets/parties/parties.json";
import PartyLogo from "@/components/PartyLogo";
import globalConfig from "@/assets/globalConfig.json";
import HorizontalRange from "@/components/HorizontalRange";
import TopicSelect from "@/components/TopicSelect";
export default {
  props: [
    "partyID",
    "sectionData",
    "visibleElements",
    "selectedSpeaker",
    "selectedPeriod",
    "selectedTopicID"
  ],
  components: {
    HorizontalRange,
    TopicSelect,
    PartyLogo
  },
  data() {
    return {
      speakerPositions: null,
      globalConfig,
      parties
    };
  },
  methods: {
    getSpeakerPositions: function() {
      let topic = this.selectedTopicID;
      if (topic === 99) {
        topic = 999;
      }
      let period = 999;
      if (this.selectedPeriod !== "allPeriods") {
        period = Number(this.selectedPeriod.slice(6, 7)) + 2;
      }
      let url =
        this.globalConfig.apiBaseUrl +
        `/speaker/speech_similarity/${
          this.selectedSpeaker.politicianID
        }/${period}/${topic}`;
      // console.log("url", url);
      axios
        .get(url)
        .then(response => (this.speakerPositions = response.data.response));
    },
    getStaticPoliticianImg: function(speaker) {
      let url =
        this.globalConfig.staticAssetsCDN +
        "/images/" +
        "politicianID_" +
        speaker +
        "_size_240.jpg";
      return url;
    }
  },
  computed: {
    topicsList: function() {
      return this.sectionData.map(x => {
        let response = {
          topicID: x.topicID,
          topicName: x.topicName
        };
        return response;
      });
    },

    rangeData: function() {
      if (this.selectedSpeaker === null) {
        let allData = this.sectionData
          .filter(x => x.topicID === this.selectedTopicID)[0]
          .partyLanguageSimilarity.map(x => {
            let response = {
              percentage: x.distance * 100,
              partyID: x.partyID
            };
            return response;
          })
          .sort((prev, curr) => prev.percentage < curr.percentage);
        if (this.visibleElements === "start") {
          return [allData[allData.length - 1]];
        } else if (this.visibleElements === "middle") {
          return [allData[0], allData[1], allData[allData.length - 1]];
        } else {
          return allData;
        }
      } else {
        if (this.speakerPositions !== null) {
          let allData = this.speakerPositions
            .map(x => {
              let response = {
                percentage: x.distance * 100,
                partyID: x.toPersonParty,
                toPerson: x.toPerson,
                toPoliticianID: x.toPoliticianId
              };
              return response;
            })
            .sort((prev, curr) => curr.percentage - prev.percentage);
          return allData;
        }
      }
    }
  },
  mounted() {
    if (this.selectedSpeaker !== null) {
      this.getSpeakerPositions();
    }
  },
  watch: {
    selectedSpeaker: function() {
      if (this.selectedSpeaker !== null) {
        this.getSpeakerPositions();
      } else {
        this.speakerPositions = null;
      }
    },
    selectedPeriod: function() {
      if (this.selectedSpeaker !== null) {
        this.getSpeakerPositions();
      } else {
        return;
      }
    },
    selectedTopicID: function() {
      if (this.selectedSpeaker !== null) {
        this.getSpeakerPositions();
      } else {
        return;
      }
    }
  }
};
</script>
